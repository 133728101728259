import React, { Component } from "react";
import "../../assets/scss/AboutPage.scss";
import $ from "jquery";
import { Helmet } from "react-helmet";

class AboutPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rand: "fsa" + new Date().getTime() + Math.floor(Math.random() * 100 + 1),
    };
  }
  handleLoad() {
    var id = this.state.rand;
    $(".rslides").responsiveSlides({
      auto: true, // Boolean: Animate automatically, true or false
      speed: 700, // Integer: Speed of the transition, in milliseconds
      timeout: 6000, // Integer: Time between slide transitions, in milliseconds
      pager: false, // Boolean: Show pager, true or false
      nav: false, // Boolean: Show navigation, true or false
      random: false, // Boolean: Randomize the order of the slides, true or false
      pause: false, // Boolean: Pause on hover, true or false
      pauseControls: true, // Boolean: Pause when hovering controls, true or false
      prevText: "Previous", // String: Text for the "previous" button
      nextText: "Next", // String: Text for the "next" button
      maxwidth: "", // Integer: Max-width of the slideshow, in pixels
      navContainer: "", // Selector: Where controls should be appended to, default is after the 'ul'
      manualControls: "", // Selector: Declare custom pager navigation
      namespace: "rslides", // String: Change the default namespace used
      before: function () {
        $("#" + id + " .custom-an").removeClass("fadeInDown visible");
      }, // Function: Before callback
      after: function () {
        $("#" + id + " li .custom-an").addClass("fadeInDown visible");
      }, // Function: After callback
    });
  }

  componentDidMount() {
    this.handleLoad();
  }
  render() {
    return (
      <div id="home-page">
        <Helmet>
        <link rel="canonical" href="https://daralataa.com/about" />
        </Helmet>
        <section id="about">
          <div className="container">
            <div className="col-sm-6 hidden-xs">
              {/*<div className="img"/>*/}
            </div>
            <div className="col-xs-12 col-sm-6 content">
              <div className="heading theme-line">
                <h3>Dar Al Ataa LLC</h3>
              </div>
              {/*<div className='heading'><h3>Dar Al Ataa  LLC</h3></div>*/}
              <br />
              <div className="about-description">
                <p>
                  <strong>
                  Dar Al Ataa LLC has established itself as one of the leading and most favoured companies to provide Building Facility Management, Electrical Works, Plumbing Works, HVAC Works, ICT Systems, Annual Maintenance Contracts(AMC), Interior Designing & Fit-out Works, Civil Works and Material Trading services in Dubai. As a leading Facility Management Company in Dubai, we deliver wide range of solutions, including Garbage Chute Maintenance & Repairing, MEP & General Maintenance, Cleaning Services, Lifts & Lifting Equipment, and more, all in line with perfection and creativity.
                  </strong>
                </p>
                <p>
                With 10 years of experience, we understand and value the power of client relationships. By building a foundation of trust and transparency with each client, we ensure that projects are completed on budget and on time.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default AboutPage;
