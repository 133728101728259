import React from 'react'
import ReactDOM from 'react-dom'
import './assets/scss/index.scss'
import App from './components/App'
import './assets/css/fontello.css'
import './assets/css/animation.css'
import './assets/scripts/responsiveslides'
import './assets/scripts/appear'
import registerServiceWorker from './registerServiceWorker'
import { BrowserRouter } from 'react-router-dom'

ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
    , document.getElementById('root')
);
registerServiceWorker();
