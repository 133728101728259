import React, { Component } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./sidebar";
import "../../assets/scss/blogs.scss";
import Page_header from "./page-header";
import blog1Img from "../../assets/images/blog1.avif";
import blog2Img from "../../assets/images/blog2.avif";
import blog3Img from "../../assets/images/blog3.avif";
import blog4Img from "../../assets/images/blog4.webp";
import blog5Img from "../../assets/images/blog5.webp";
import blog6Img from "../../assets/images/lifting-equipment.webp";
import blog7Img from "../../assets/images/blog7.webp";
import blog8Img from "../../assets/images/garbage-chute.jpg";
import blog9Img from "../../assets/images/cctv-maintenance.jpg";
import blog10Img from "../../assets/images/ac-installation-services.jpg";
import blog11Img from "../../assets/images/water-tank-cleaning-solution.jpg";
import blog12Img from "../../assets/images/top-10-hvac-companies-dubai.jpg";
import blog13Img from "../../assets/images/hvac-services-dubai-guide.jpg";
import blog14Img from "../../assets/images/hvac-maintenance-companies-dubai-efficient-cooling.jpeg";
import blog15Img from "../../assets/images/technology-in-building-facility-management-dubai.jpg";
import blog16Img from "../../assets/images/preventative-maintenance-building-facility-management-dubai.jpg";
import blog17Img from "../../assets/images/dar-al-ataa-best-hvac-services-dubai.jpeg";
import blog18Img from "../../assets/images/choosing-facility-management-company-dubai.jpg";
import blog19Img from "../../assets/images/regular-plumbing-maintenance-dubai.jpg";
import blog20Img from "../../assets/images/common-plumbing-issues-dubai-solutions.jpg";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments, faCalendarDays } from "@fortawesome/free-solid-svg-icons";
class BlogList extends Component {
  render() {
    return (
      <>
        <Page_header headertitle="Blog" subheader="Blog" />
        <div className="ltn__blog-area mb-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="ltn__blog-list-wrap">


                   {/* Blog Item */}
                   <div className="ltn__blog-item ltn__blog-item-5 go-top">
                    <div className="ltn__blog-img">
                      <Link to="/common-plumbing-issues-in-dubai-solutions">
                        <img src={blog20Img} alt="A plumber fixing a pipe leak in a modern Dubai home to prevent water damage and costly repairs" title="Common Plumbing Issues in Dubai and How to Solve Them"/>
                      </Link>
                    </div>
                    <div className="ltn__blog-brief">
                      <h3 className="ltn__blog-title">
                        <Link to="/common-plumbing-issues-in-dubai-solutions">
                        What Are the Most Common Plumbing Issues in Dubai and How Can You Solve Them?
                        </Link>
                      </h3>
                      <div className="ltn__blog-meta">
                        <ul>
                          <li>
                            <Link to="/common-plumbing-issues-in-dubai-solutions">
                              <FontAwesomeIcon
                                icon={faComments}
                                style={{ color: "#b03c3d" }}
                              />
                              35 Comments
                            </Link>
                          </li>
                          <li className="ltn__blog-date">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              style={{ color: "#b03c3d" }}
                            />
                            December 09, 2024
                          </li>
                        </ul>
                      </div>
                      <p>The urban appeal of Dubai, defined by luxurious structures and state-of-the-art utilities, can hide specific plumbing problems related </p>
                      <div className="ltn__blog-meta-btn">
                        <div className="ltn__blog-btn">
                          <Link to="/common-plumbing-issues-in-dubai-solutions">
                            <i className="fas fa-arrow-right" />
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>


                  {/* Blog Item */}
                  <div className="ltn__blog-item ltn__blog-item-5 go-top">
                    <div className="ltn__blog-img">
                      <Link to="/Best-Plumbing-Service-in-Dubai">
                        <img src={blog19Img} alt="A professional plumber performing regular plumbing maintenance in Dubai to prevent costly repairs" title="Regular Plumbing Maintenance in Dubai"/>
                      </Link>
                    </div>
                    <div className="ltn__blog-brief">
                      <h3 className="ltn__blog-title">
                        <Link to="/Best-Plumbing-Service-in-Dubai">
                        How Can Regular Plumbing Maintenance Prevent Costly Repairs in Dubai? 
                        </Link>
                      </h3>
                      <div className="ltn__blog-meta">
                        <ul>
                          <li>
                            <Link to="/Best-Plumbing-Service-in-Dubai">
                              <FontAwesomeIcon
                                icon={faComments}
                                style={{ color: "#b03c3d" }}
                              />
                              35 Comments
                            </Link>
                          </li>
                          <li className="ltn__blog-date">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              style={{ color: "#b03c3d" }}
                            />
                            December 07, 2024
                          </li>
                        </ul>
                      </div>
                      <p>Plumbing is one of the critical systems of a property, but it is frequently ignored until there is a problem. In Dubai where advanced </p>
                      <div className="ltn__blog-meta-btn">
                        <div className="ltn__blog-btn">
                          <Link to="/Best-Plumbing-Service-in-Dubai">
                            <i className="fas fa-arrow-right" />
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>



                  {/* Blog Item */}
                  <div className="ltn__blog-item ltn__blog-item-5 go-top">
                    <div className="ltn__blog-img">
                      <Link to="/choosing-right-facility-management-company-dubai">
                        <img src={blog18Img} alt="Facility manager inspecting a commercial building in Dubai with a focus on technology, sustainability, and safety" title="Choosing the Right Facility Management Company in Dubai"/>
                      </Link>
                    </div>
                    <div className="ltn__blog-brief">
                      <h3 className="ltn__blog-title">
                        <Link to="/choosing-right-facility-management-company-dubai">
                        How to Choose the Right Facility Management Company in Dubai for Your Building
                        </Link>
                      </h3>
                      <div className="ltn__blog-meta">
                        <ul>
                          <li>
                            <Link to="/choosing-right-facility-management-company-dubai">
                              <FontAwesomeIcon
                                icon={faComments}
                                style={{ color: "#b03c3d" }}
                              />
                              35 Comments
                            </Link>
                          </li>
                          <li className="ltn__blog-date">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              style={{ color: "#b03c3d" }}
                            />
                            November 13, 2024
                          </li>
                        </ul>
                      </div>
                      <p>
                      Facility management is an integral aspect of maintaining and boosting the functionality, safety, and value of any building.</p>
                      <div className="ltn__blog-meta-btn">
                        <div className="ltn__blog-btn">
                          <Link to="/choosing-right-facility-management-company-dubai">
                            <i className="fas fa-arrow-right" />
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>




                  {/* Blog Item */}
                  <div className="ltn__blog-item ltn__blog-item-5 go-top">
                    <div className="ltn__blog-img">
                      <Link to="/best-hvac-services-in-dubai">
                        <img src={blog17Img} alt="Dar Al Ataa technician providing HVAC services in Dubai cityscape with advanced, energy-efficient HVAC system" title="Dar Al Ataa - Best HVAC Services in Dubai"/>
                      </Link>
                    </div>
                    <div className="ltn__blog-brief">
                      <h3 className="ltn__blog-title">
                        <Link to="/best-hvac-services-in-dubai">
                        Why Dar Al Ataa Takes the Best HVAC Services in Dubai 
                        </Link>
                      </h3>
                      <div className="ltn__blog-meta">
                        <ul>
                          <li>
                            <Link to="/best-hvac-services-in-dubai">
                              <FontAwesomeIcon
                                icon={faComments}
                                style={{ color: "#b03c3d" }}
                              />
                              35 Comments
                            </Link>
                          </li>
                          <li className="ltn__blog-date">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              style={{ color: "#b03c3d" }}
                            />
                            November 13, 2024
                          </li>
                        </ul>
                      </div>
                      <p>
                      Dubai has an extreme climate that demands a reliable and efficient HVAC system.</p>
                      <div className="ltn__blog-meta-btn">
                        <div className="ltn__blog-btn">
                          <Link to="/best-hvac-services-in-dubai">
                            <i className="fas fa-arrow-right" />
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>



                  {/* Blog Item */}
                  <div className="ltn__blog-item ltn__blog-item-5 go-top">
                    <div className="ltn__blog-img">
                      <Link to="/preventative-maintenance-building-facility-management-dubai">
                        <img src={blog16Img} alt="Preventative maintenance in Dubai's building facilities ensuring efficient operations and sustainability" title="Preventative Maintenance in Dubai's Building Facility Management"/>
                      </Link>
                    </div>
                    <div className="ltn__blog-brief">
                      <h3 className="ltn__blog-title">
                        <Link to="/preventative-maintenance-building-facility-management-dubai">
                        A Comprehensive Guide to Preventative Maintenance in Dubai’s Building Facilities  
                        </Link>
                      </h3>
                      <div className="ltn__blog-meta">
                        <ul>
                          <li>
                            <Link to="/preventative-maintenance-building-facility-management-dubai">
                              <FontAwesomeIcon
                                icon={faComments}
                                style={{ color: "#b03c3d" }}
                              />
                              35 Comments
                            </Link>
                          </li>
                          <li className="ltn__blog-date">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              style={{ color: "#b03c3d" }}
                            />
                            November 13, 2024
                          </li>
                        </ul>
                      </div>
                      <p>
                      In Dubai, a city renowned for its iconic architecture and high-performance buildings, maintaining properties </p>
                      <div className="ltn__blog-meta-btn">
                        <div className="ltn__blog-btn">
                          <Link to="/preventative-maintenance-building-facility-management-dubai">
                            <i className="fas fa-arrow-right" />
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>





                  {/* Blog Item */}
                  <div className="ltn__blog-item ltn__blog-item-5 go-top">
                    <div className="ltn__blog-img">
                      <Link to="/technology-building-facility-management-dubai">
                        <img src={blog15Img} alt="Advanced technology for building facility management in Dubai, showcasing IoT, automation, and data-driven tools for sustainable operations" title="Technology in Building Facility Management in Dubai"/>
                      </Link>
                    </div>
                    <div className="ltn__blog-brief">
                      <h3 className="ltn__blog-title">
                        <Link to="/technology-building-facility-management-dubai">
                        The Role of Technology in Modern Building Facility Management in Dubai
                        </Link>
                      </h3>
                      <div className="ltn__blog-meta">
                        <ul>
                          <li>
                            <Link to="/technology-building-facility-management-dubai">
                              <FontAwesomeIcon
                                icon={faComments}
                                style={{ color: "#b03c3d" }}
                              />
                              35 Comments
                            </Link>
                          </li>
                          <li className="ltn__blog-date">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              style={{ color: "#b03c3d" }}
                            />
                            November 13, 2024
                          </li>
                        </ul>
                      </div>
                      <p>
                      Dubai is an emirate of innovation and luxury. Today, Dubai is synonymous with the world's </p>
                      <div className="ltn__blog-meta-btn">
                        <div className="ltn__blog-btn">
                          <Link to="/technology-building-facility-management-dubai">
                            <i className="fas fa-arrow-right" />
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>




                  {/* Blog Item */}
                  <div className="ltn__blog-item ltn__blog-item-5 go-top">
                    <div className="ltn__blog-img">
                      <Link to="/hvac-maintenance-companies-dubai">
                        <img src={blog14Img} alt="HVAC maintenance technician in Dubai working on cooling system for efficient performance and durability" title="HVAC Maintenance Companies in Dubai for Efficient Cooling - Dar Al Ataa"/>
                      </Link>
                    </div>
                    <div className="ltn__blog-brief">
                      <h3 className="ltn__blog-title">
                        <Link to="/hvac-maintenance-companies-dubai">
                        HVAC Maintenance Companies in Dubai: The Key to Efficient Cooling Systems
                        </Link>
                      </h3>
                      <div className="ltn__blog-meta">
                        <ul>
                          <li>
                            <Link to="/hvac-maintenance-companies-dubai">
                              <FontAwesomeIcon
                                icon={faComments}
                                style={{ color: "#b03c3d" }}
                              />
                              35 Comments
                            </Link>
                          </li>
                          <li className="ltn__blog-date">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              style={{ color: "#b03c3d" }}
                            />
                            November 13, 2024
                          </li>
                        </ul>
                      </div>
                      <p>
                      Dubai's scorching heat and humid climate make reliable HVAC systems essential for both </p>
                      <div className="ltn__blog-meta-btn">
                        <div className="ltn__blog-btn">
                          <Link to="/hvac-maintenance-companies-dubai">
                            <i className="fas fa-arrow-right" />
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>


                 
                  {/* Blog Item */}
                  <div className="ltn__blog-item ltn__blog-item-5 go-top">
                    <div className="ltn__blog-img">
                      <Link to="/comprehensive-guide-hvac-services-dubai">
                        <img src={blog13Img} alt="Guide to HVAC services in Dubai, including installation, maintenance, and repairs by Dar Al Ataa
" title="Comprehensive Guide to HVAC Services in Dubai"/>
                      </Link>
                    </div>
                    <div className="ltn__blog-brief">
                      <h3 className="ltn__blog-title">
                        <Link to="/comprehensive-guide-hvac-services-dubai">
                        Comprehensive Guide to HVAC Services in Dubai: What You Need to Know
                        </Link>
                      </h3>
                      <div className="ltn__blog-meta">
                        <ul>
                          <li>
                            <Link to="/comprehensive-guide-hvac-services-dubai">
                              <FontAwesomeIcon
                                icon={faComments}
                                style={{ color: "#b03c3d" }}
                              />
                              35 Comments
                            </Link>
                          </li>
                          <li className="ltn__blog-date">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              style={{ color: "#b03c3d" }}
                            />
                            November 04, 2024
                          </li>
                        </ul>
                      </div>
                      <p>
                      Dubai’s climate is characterized by intense heat for much of the year, making efficient and reliable heating, ventilation,  </p>
                      <div className="ltn__blog-meta-btn">
                        <div className="ltn__blog-btn">
                          <Link to="/comprehensive-guide-hvac-services-dubai">
                            <i className="fas fa-arrow-right" />
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>



                   {/* Blog Item */}
                   <div className="ltn__blog-item ltn__blog-item-5 go-top">
                    <div className="ltn__blog-img">
                      <Link to="/water-tank-cleaning-solution-in-dubai">
                        <img src={blog12Img} alt="Top 10 HVAC companies in Dubai, including Dar Al Ataa for quality HVAC solutions
" title="Top 10 HVAC Companies in Dubai - Dar Al Ataa"/>
                      </Link>
                    </div>
                    <div className="ltn__blog-brief">
                      <h3 className="ltn__blog-title">
                        <Link to="/top-10-hvac-companies-dubai">
                        Top 10 HVAC Companies in Dubai: Why Dar Al Ataa Stands Out
                        </Link>
                      </h3>
                      <div className="ltn__blog-meta">
                        <ul>
                          <li>
                            <Link to="/top-10-hvac-companies-dubai">
                              <FontAwesomeIcon
                                icon={faComments}
                                style={{ color: "#b03c3d" }}
                              />
                              35 Comments
                            </Link>
                          </li>
                          <li className="ltn__blog-date">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              style={{ color: "#b03c3d" }}
                            />
                            November 04, 2024
                          </li>
                        </ul>
                      </div>
                      <p>
                      With its year-round heat, Dubai’s climate demands reliable heating, ventilation, and air conditioning (HVAC) systems    </p>
                      <div className="ltn__blog-meta-btn">
                        <div className="ltn__blog-btn">
                          <Link to="/top-10-hvac-companies-dubai">
                            <i className="fas fa-arrow-right" />
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>



                  {/* Blog Item */}
                  <div className="ltn__blog-item ltn__blog-item-5 go-top">
                    <div className="ltn__blog-img">
                      <Link to="/water-tank-cleaning-solution-in-dubai">
                        <img src={blog11Img} alt="Image" />
                      </Link>
                    </div>
                    <div className="ltn__blog-brief">
                      <h3 className="ltn__blog-title">
                        <Link to="/water-tank-cleaning-solution-in-dubai">
                        Professional Water Tank Cleaning Solutions in Dubai
                        </Link>
                      </h3>
                      <div className="ltn__blog-meta">
                        <ul>
                          <li>
                            <Link to="/water-tank-cleaning-solution-in-dubai">
                              <FontAwesomeIcon
                                icon={faComments}
                                style={{ color: "#b03c3d" }}
                              />
                              35 Comments
                            </Link>
                          </li>
                          <li className="ltn__blog-date">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              style={{ color: "#b03c3d" }}
                            />
                            September 21, 2024
                          </li>
                        </ul>
                      </div>
                      <p>
                      In the vibrant metropolis of Dubai, the upkeep of clean and sanitary water tanks is vital for guaranteeing a safe water supply for residences                      </p>
                      <div className="ltn__blog-meta-btn">
                        <div className="ltn__blog-btn">
                          <Link to="/water-tank-cleaning-solution-in-dubai">
                            <i className="fas fa-arrow-right" />
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>


                  {/* Blog Item */}
                  <div className="ltn__blog-item ltn__blog-item-5 go-top">
                    <div className="ltn__blog-img">
                      <Link to="/cctv-maintenance-services-in-uae">
                        <img src={blog10Img} alt="Image" />
                      </Link>
                    </div>
                    <div className="ltn__blog-brief">
                      <h3 className="ltn__blog-title">
                        <Link to="/ac-installation-services-in-uae">
                        Stay Cool with The Best AC Installation Services in UAE
                        </Link>
                      </h3>
                      <div className="ltn__blog-meta">
                        <ul>
                          <li>
                            <Link to="/ac-installation-services-in-uae">
                              <FontAwesomeIcon
                                icon={faComments}
                                style={{ color: "#b03c3d" }}
                              />
                              35 Comments
                            </Link>
                          </li>
                          <li className="ltn__blog-date">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              style={{ color: "#b03c3d" }}
                            />
                            September 20, 2024
                          </li>
                        </ul>
                      </div>
                      <p>
                      In the extreme heat of the UAE, air conditioning isn't just a luxury, it’s a necessity. Whether it’s a sweltering summer day or a warm afternoon.
                      </p>
                      <div className="ltn__blog-meta-btn">
                        <div className="ltn__blog-btn">
                          <Link to="/ac-installation-services-in-uae">
                            <i className="fas fa-arrow-right" />
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Blog Item */}
                  <div className="ltn__blog-item ltn__blog-item-5 go-top">
                    <div className="ltn__blog-img">
                      <Link to="/cctv-maintenance-services-in-uae">
                        <img src={blog9Img} alt="Image" />
                      </Link>
                    </div>
                    <div className="ltn__blog-brief">
                      <h3 className="ltn__blog-title">
                        <Link to="/cctv-maintenance-services-in-uae">
                        Reliable CCTV Maintenance Services in UAE 
                        </Link>
                      </h3>
                      <div className="ltn__blog-meta">
                        <ul>
                          <li>
                            <Link to="/cctv-maintenance-services-in-uae">
                              <FontAwesomeIcon
                                icon={faComments}
                                style={{ color: "#b03c3d" }}
                              />
                              35 Comments
                            </Link>
                          </li>
                          <li className="ltn__blog-date">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              style={{ color: "#b03c3d" }}
                            />
                            September 16, 2024
                          </li>
                        </ul>
                      </div>
                      <p>
                      In today’s world, security is a top priority for businesses, residential complexes, and institutions. With the growing need to protect property and people.  
                      </p>
                      <div className="ltn__blog-meta-btn">
                        <div className="ltn__blog-btn">
                          <Link to="/cctv-maintenance-services-in-uae">
                            <i className="fas fa-arrow-right" />
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Blog Item */}
                  <div className="ltn__blog-item ltn__blog-item-5 go-top">
                    <div className="ltn__blog-img">
                      <Link to="/generator-maintenance-and-spare-parts-solutions-in-UAE-dubai">
                        <img src={blog8Img} alt="Image" />
                      </Link>
                    </div>
                    <div className="ltn__blog-brief">
                      <h3 className="ltn__blog-title">
                        <Link to="/best-garbage-chute-maintenance-service-in-dubai">
                        Garbage Chute Maintenance in Dubai 
                        </Link>
                      </h3>
                      <div className="ltn__blog-meta">
                        <ul>
                          <li>
                            <Link to="/best-garbage-chute-maintenance-service-in-dubai">
                              <FontAwesomeIcon
                                icon={faComments}
                                style={{ color: "#b03c3d" }}
                              />
                              35 Comments
                            </Link>
                          </li>
                          <li className="ltn__blog-date">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              style={{ color: "#b03c3d" }}
                            />
                            September 14, 2024
                          </li>
                        </ul>
                      </div>
                      <p>
                      In highly urban environments like Dubai, effective waste management is a critical aspect of maintaining cleanliness, health, and safety.  
                      </p>
                      <div className="ltn__blog-meta-btn">
                        <div className="ltn__blog-btn">
                          <Link to="/best-garbage-chute-maintenance-service-in-dubai">
                            <i className="fas fa-arrow-right" />
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Blog Item */}
                  <div className="ltn__blog-item ltn__blog-item-5 go-top">
                    <div className="ltn__blog-img">
                      <Link to="/generator-maintenance-and-spare-parts-solutions-in-UAE-dubai">
                        <img src={blog7Img} alt="Image" />
                      </Link>
                    </div>
                    <div className="ltn__blog-brief">
                      <h3 className="ltn__blog-title">
                        <Link to="/generator-maintenance-and-spare-parts-solutions-in-UAE-dubai">
                        Generator Maintenance and Spare Parts Solutions in UAE Dubai  
                        </Link>
                      </h3>
                      <div className="ltn__blog-meta">
                        <ul>
                          <li>
                            <Link to="/generator-maintenance-and-spare-parts-solutions-in-UAE-dubai">
                              <FontAwesomeIcon
                                icon={faComments}
                                style={{ color: "#b03c3d" }}
                              />
                              35 Comments
                            </Link>
                          </li>
                          <li className="ltn__blog-date">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              style={{ color: "#b03c3d" }}
                            />
                            August 10, 2024
                          </li>
                        </ul>
                      </div>
                      <p>
                      In today's fast-paced world, a reliable power supply is crucial for the seamless operation of businesses and homes.  
                      </p>
                      <div className="ltn__blog-meta-btn">
                        <div className="ltn__blog-btn">
                          <Link to="/generator-maintenance-and-spare-parts-solutions-in-UAE-dubai">
                            <i className="fas fa-arrow-right" />
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Blog Item */}
                  <div className="ltn__blog-item ltn__blog-item-5 go-top">
                    <div className="ltn__blog-img">
                      <Link to="/lifting-equipment-in-dubai">
                        <img src={blog6Img} alt="Image" />
                      </Link>
                    </div>
                    <div className="ltn__blog-brief">
                      <h3 className="ltn__blog-title">
                        <Link to="/lifting-equipment-in-dubai">
                        Top Quality Lifting Equipment Solutions in UAE
                        </Link>
                      </h3>
                      <div className="ltn__blog-meta">
                        <ul>
                          <li>
                            <Link to="/lifting-equipment-in-dubai">
                              <FontAwesomeIcon
                                icon={faComments}
                                style={{ color: "#b03c3d" }}
                              />
                              35 Comments
                            </Link>
                          </li>
                          <li className="ltn__blog-date">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              style={{ color: "#b03c3d" }}
                            />
                            August 10, 2024
                          </li>
                        </ul>
                      </div>
                      <p>
                      Dar Al Ataa is recognized as a premier provider of top quality lifting equipment solutions, offering an extensive array of options specifically designed to cater to the varied requirements of industries throughout the region. 
                      </p>
                      <div className="ltn__blog-meta-btn">
                        <div className="ltn__blog-btn">
                          <Link to="/lifting-equipment-in-dubai">
                            <i className="fas fa-arrow-right" />
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Blog Item */}
                  <div className="ltn__blog-item ltn__blog-item-5 go-top">
                    <div className="ltn__blog-img">
                      <Link to="/facade-cleaning-services-in-dubai">
                        <img src={blog5Img} alt="Image" />
                      </Link>
                    </div>
                    <div className="ltn__blog-brief">
                      <h3 className="ltn__blog-title">
                        <Link to="/facade-cleaning-services-in-dubai">
                        Best Facade Cleaning Services in Dubai
                        </Link>
                      </h3>
                      <div className="ltn__blog-meta">
                        <ul>
                          <li>
                            <Link to="/facade-cleaning-services-in-dubai">
                              <FontAwesomeIcon
                                icon={faComments}
                                style={{ color: "#b03c3d" }}
                              />
                              35 Comments
                            </Link>
                          </li>
                          <li className="ltn__blog-date">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              style={{ color: "#b03c3d" }}
                            />
                            August 09, 2024
                          </li>
                        </ul>
                      </div>
                      <p>
                      Maintaining a building's façade is essential to both its structural integrity and its pleasing appearance. At Dar Al Ataa, we provide the best facade cleaning services that enhance your property's longevity and attractiveness. 
                      </p>
                      <div className="ltn__blog-meta-btn">
                        <div className="ltn__blog-btn">
                          <Link to="/facade-cleaning-services-in-dubai">
                            <i className="fas fa-arrow-right" />
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Blog Item */}
                  <div className="ltn__blog-item ltn__blog-item-5 go-top">
                    <div className="ltn__blog-img">
                      <Link to="/mep-maintenance-company-in-uae">
                        <img src={blog4Img} alt="Image" />
                      </Link>
                    </div>
                    <div className="ltn__blog-brief">
                      <h3 className="ltn__blog-title">
                        <Link to="/mep-maintenance-company-in-uae">
                          Innovative MEP Services in Dubai
                        </Link>
                      </h3>
                      <div className="ltn__blog-meta">
                        <ul>
                          <li>
                            <Link to="/mep-maintenance-company-in-uae">
                              <FontAwesomeIcon
                                icon={faComments}
                                style={{ color: "#b03c3d" }}
                              />
                              35 Comments
                            </Link>
                          </li>
                          <li className="ltn__blog-date">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              style={{ color: "#b03c3d" }}
                            />
                            August 09, 2024
                          </li>
                        </ul>
                      </div>
                      <p>
                        In the vibrant city of Dubai, the need for the best MEP services continues to grow. MEP (Mechanical, Electrical, and Plumbing) systems, are essential for the infrastructure of contemporary buildings. Dar Al Ataa, the best among MEP maintenance companies in Dubai
                      </p>
                      <div className="ltn__blog-meta-btn">
                        <div className="ltn__blog-btn">
                          <Link to="/mep-maintenance-company-in-uae">
                            <i className="fas fa-arrow-right" />
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Blog Item */}
                  <div className="ltn__blog-item ltn__blog-item-5 go-top">
                    <div className="ltn__blog-img">
                      <Link to="/best-solar-installation-in-dubai-uae">
                        <img src={blog3Img} alt="Image" />
                      </Link>
                    </div>
                    <div className="ltn__blog-brief">
                      <h3 className="ltn__blog-title">
                        <Link to="/best-solar-installation-in-dubai-uae">
                          The Best Solar Energy Systems Installation Services in
                          Dubai
                        </Link>
                      </h3>
                      <div className="ltn__blog-meta">
                        <ul>
                          <li>
                            <Link to="/best-solar-installation-in-dubai-uae">
                              <FontAwesomeIcon
                                icon={faComments}
                                style={{ color: "#b03c3d" }}
                              />
                              35 Comments
                            </Link>
                          </li>
                          <li className="ltn__blog-date">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              style={{ color: "#b03c3d" }}
                            />
                            July 19, 2024
                          </li>
                        </ul>
                      </div>
                      <p>
                        Dubai's got sunshine in abundance, so why not put it to
                        good use? We know that understanding the solar panel
                        industry might be a little intimidating. But don’t
                        worry, Dar Al Ataa, the top solar panel installers and
                        suppliers in Dubai, UAE, is available to assist you at
                        every turn.
                      </p>
                      <div className="ltn__blog-meta-btn">
                        <div className="ltn__blog-btn">
                          <Link to="/best-solar-installation-in-dubai-uae">
                            <i className="fas fa-arrow-right" />
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Blog Item */}
                  <div className="ltn__blog-item ltn__blog-item-5 go-top">
                    <div className="ltn__blog-img">
                      <Link to="/plumbing-and-sanitary-contracting-in-dubai-uae">
                        <img src={blog2Img} alt="Image" />
                      </Link>
                    </div>
                    <div className="ltn__blog-brief">
                      <h3 className="ltn__blog-title">
                        <Link to="/plumbing-and-sanitary-contracting-in-dubai-uae">
                          pluming services
                        </Link>
                      </h3>
                      <div className="ltn__blog-meta">
                        <ul>
                          <li>
                            <Link to="/plumbing-and-sanitary-contracting-in-dubai-uae">
                              <FontAwesomeIcon
                                icon={faComments}
                                style={{ color: "#b03c3d" }}
                              />
                              35 Comments
                            </Link>
                          </li>
                          <li className="ltn__blog-date">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              style={{ color: "#b03c3d" }}
                            />
                            July 16, 2024
                          </li>
                        </ul>
                      </div>
                      <p>
                        The plumbing problems can be a real headache. Leaky
                        faucets, clogged drains, malfunctioning toilets – these
                        issues can disrupt your daily routine and leave you
                        feeling frustrated. But fear not, Dar Al Ataa is here to
                        be your heroes in the world of pipes and fixtures with
                        our top plumbing & sanitary contracting services in
                        Dubai.
                      </p>
                      <div className="ltn__blog-meta-btn">
                        <div className="ltn__blog-btn">
                          <Link to="/plumbing-and-sanitary-contracting-in-dubai-uae">
                            <i className="fas fa-arrow-right" />
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Blog Item (Video) */}
                  <div className="ltn__blog-item ltn__blog-item-5 ltn__blog-item-video">
                    <div className="ltn__video-img">
                      <img src={blog1Img} alt="video popup bg image" />
                      <a
                        className="ltn__video-icon-2 ltn__secondary-bg ltn__video-icon-2-border---"
                        href="https://www.youtube.com/embed/X7R-q9rsrtU?autoplay=1&showinfo=0"
                        data-rel="lightcase:myCollection"
                      >
                        <i className="fa fa-play" />
                      </a>
                    </div>
                    <div className="ltn__blog-brief go-top">
                      <h3 className="ltn__blog-title">
                        <Link to="/electromechanical-equipment-installation-and-maintenance-services-in-dubai">
                          Electromechanical Equipment Installation & Maintenance
                        </Link>
                      </h3>
                      <div className="ltn__blog-meta">
                        <ul>
                          <li>
                            <Link to="/electromechanical-equipment-installation-and-maintenance-services-in-dubai">
                              <FontAwesomeIcon
                                icon={faComments}
                                style={{ color: "#b03c3d" }}
                              />
                              35 Comments
                            </Link>
                          </li>
                          <li className="ltn__blog-date">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              style={{ color: "#b03c3d" }}
                            />
                            July 16, 2024
                          </li>
                        </ul>
                      </div>
                      <p>
                        Dar Al Ataa LLC specializes in electromechanical
                        services and solutions, especially, in electromechanical
                        equipment installation and maintenance to optimize the
                        functionality and efficiency of your building. Our
                        tailored electromechanical work caters to diverse
                        clients in different sectors, ensuring your specific
                        needs are met.
                      </p>
                      <div className="ltn__blog-meta-btn">
                        <div className="ltn__blog-btn">
                          <Link to="/electromechanical-equipment-installation-and-maintenance-services-in-dubai">
                            <i className="fas fa-arrow-right" />
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*  */}
                </div>
              </div>
              <Sidebar />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default BlogList;
