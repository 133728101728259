import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../../assets/scss/sidebar.scss";
import blog1Img from "../../assets/images/blog1.avif";
import blog2Img from "../../assets/images/blog2.avif";
import blog3Img from "../../assets/images/blog3.avif";
import blog4Img from "../../assets/images/blog4.webp";
import blog5Img from "../../assets/images/blog5.webp";
import blog6Img from "../../assets/images/lifting-equipment.webp";
import blog7Img from "../../assets/images/blog7.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments, faCalendarDays } from "@fortawesome/free-solid-svg-icons";

class Sidebar extends Component {
  render() {
    let anchor = "#";
    let imagealt = "image";
    let publicUrl = process.env.PUBLIC_URL + "/";
    return (
      <div className="col-lg-4 go-top">
        <aside className="sidebar-area blog-sidebar ltn__right-sidebar">
          {/* Popular Post Widget */}
          <div className="widget ltn__popular-post-widget">
            <h4 className="ltn__widget-title ltn__widget-title-border-2">
              Latest Blogs
            </h4>
            <ul>
            <li>
                <div className="popular-post-widget-item clearfix">
                  <div className="popular-post-widget-img">
                    <Link to="/generator-maintenance-and-spare-parts-solutions-in-UAE-dubai">
                      <img src={blog7Img} alt="#" />
                    </Link>
                  </div>
                  <div className="popular-post-widget-brief">
                    <h6>
                      <Link to="/generator-maintenance-and-spare-parts-solutions-in-UAE-dubai">
                      Generator Maintenance and Spare Parts Solutions in UAE  Dubai   {" "}
                      </Link>
                    </h6>
                    <div className="ltn__blog-meta">
                      <ul className="no-point ml-0">
                        <li className="ltn__blog-date">
                          <Link to="#">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              style={{ color: "#b03c3d" }}
                            />
                            August 10, 2024
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            <li>
                <div className="popular-post-widget-item clearfix">
                  <div className="popular-post-widget-img">
                    <Link to="/lifting-equipment-in-dubai">
                      <img src={blog6Img} alt="#" />
                    </Link>
                  </div>
                  <div className="popular-post-widget-brief">
                    <h6>
                      <Link to="/lifting-equipment-in-dubai">
                      Top Quality Lifting Equipment Solutions in UAE {" "}
                      </Link>
                    </h6>
                    <div className="ltn__blog-meta">
                      <ul className="no-point ml-0">
                        <li className="ltn__blog-date">
                          <Link to="#">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              style={{ color: "#b03c3d" }}
                            />
                            August 10, 2024
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            <li>
                <div className="popular-post-widget-item clearfix">
                  <div className="popular-post-widget-img">
                    <Link to="/facade-cleaning-services-in-dubai">
                      <img src={blog5Img} alt="#" />
                    </Link>
                  </div>
                  <div className="popular-post-widget-brief">
                    <h6>
                      <Link to="/facade-cleaning-services-in-dubai">
                      Best Facade Cleaning Services in Dubai {" "}
                      </Link>
                    </h6>
                    <div className="ltn__blog-meta">
                      <ul className="no-point ml-0">
                        <li className="ltn__blog-date">
                          <Link to="#">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              style={{ color: "#b03c3d" }}
                            />
                            August 09, 2024
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            <li>
                <div className="popular-post-widget-item clearfix">
                  <div className="popular-post-widget-img">
                    <Link to="/mep-maintenance-company-in-uae">
                      <img src={blog4Img} alt="#" />
                    </Link>
                  </div>
                  <div className="popular-post-widget-brief">
                    <h6>
                      <Link to="/mep-maintenance-company-in-uae">
                      Innovative MEP Services in Dubai {" "}
                      </Link>
                    </h6>
                    <div className="ltn__blog-meta">
                      <ul className="no-point ml-0">
                        <li className="ltn__blog-date">
                          <Link to="#">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              style={{ color: "#b03c3d" }}
                            />
                            August 09, 2024
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="popular-post-widget-item clearfix">
                  <div className="popular-post-widget-img">
                    <Link to="/best-solar-installation-in-dubai-uae">
                      <img src={blog3Img} alt="#" />
                    </Link>
                  </div>
                  <div className="popular-post-widget-brief">
                    <h6>
                      <Link to="/best-solar-installation-in-dubai-uae">
                        The Best Solar Energy Systems Installation Services in
                        Dubai{" "}
                      </Link>
                    </h6>
                    <div className="ltn__blog-meta">
                      <ul className="no-point ml-0">
                        <li className="ltn__blog-date">
                          <Link to="#">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              style={{ color: "#b03c3d" }}
                            />
                            July 19, 2024
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="popular-post-widget-item clearfix">
                  <div className="popular-post-widget-img">
                    <Link to="/plumbing-and-sanitary-contracting-in-dubai-uae">
                      <img src={blog2Img} alt="#" />
                    </Link>
                  </div>
                  <div className="popular-post-widget-brief">
                    <h6>
                      <Link to="/plumbing-and-sanitary-contracting-in-dubai-uae">
                        Plumbing Services
                      </Link>
                    </h6>
                    <div className="ltn__blog-meta">
                      <ul className="no-point ml-0">
                        <li className="ltn__blog-date">
                          <Link to="#">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              style={{ color: "#b03c3d" }}
                            />
                            July 16, 2024
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="popular-post-widget-item clearfix">
                  <div className="popular-post-widget-img">
                    <Link to="/electromechanical-equipment-installation-and-maintenance-services-in-dubai">
                      <img src={blog1Img} alt="#" />
                    </Link>
                  </div>
                  <div className="popular-post-widget-brief">
                    <h6>
                      <Link to="/electromechanical-equipment-installation-and-maintenance-services-in-dubai">
                        Electromechanical Equipment Installation & Maintenance
                      </Link>
                    </h6>
                    <div className="ltn__blog-meta">
                      <ul className="no-point ml-0">
                        <li className="ltn__blog-date">
                          <Link to="#">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              style={{ color: "#b03c3d" }}
                            />
                            July 16, 2024
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </aside>
      </div>
    );
  }
}

export default Sidebar;
