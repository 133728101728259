import React, { Component } from "react";
import "../../assets/scss/ServicePage.scss";
import $ from "jquery";
import electrical from "../../assets/images/electrical.jpeg";
import pumbling from "../../assets/images/pumbling.jpg";
import ict from "../../assets/images/ict.jpg";
import hvac from "../../assets/images/hvac-work.jpg";
import amc from "../../assets/images/amc-service.jpg";
import service2 from "../../assets/images/civil-works.jpeg";
import service3 from "../../assets/images/service3.jpg";
import service1 from "../../assets/images/service1.jpeg";
// import material from '../../assets/images/material1.jpg'
import material from "../../assets/images/bm2.jpg";
import { Helmet } from "react-helmet";

import { Link } from "react-router-dom";

class ServicePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rand: "fsa" + new Date().getTime() + Math.floor(Math.random() * 100 + 1),
    };
  }
  handleLoad() {
    var id = this.state.rand;
    $(".rslides").responsiveSlides({
      auto: true, // Boolean: Animate automatically, true or false
      speed: 700, // Integer: Speed of the transition, in milliseconds
      timeout: 6000, // Integer: Time between slide transitions, in milliseconds
      pager: false, // Boolean: Show pager, true or false
      nav: false, // Boolean: Show navigation, true or false
      random: false, // Boolean: Randomize the order of the slides, true or false
      pause: false, // Boolean: Pause on hover, true or false
      pauseControls: true, // Boolean: Pause when hovering controls, true or false
      prevText: "Previous", // String: Text for the "previous" button
      nextText: "Next", // String: Text for the "next" button
      maxwidth: "", // Integer: Max-width of the slideshow, in pixels
      navContainer: "", // Selector: Where controls should be appended to, default is after the 'ul'
      manualControls: "", // Selector: Declare custom pager navigation
      namespace: "rslides", // String: Change the default namespace used
      before: function () {
        $("#" + id + " .custom-an").removeClass("fadeInDown visible");
      }, // Function: Before callback
      after: function () {
        $("#" + id + " li .custom-an").addClass("fadeInDown visible");
      }, // Function: After callback
    });
  }

  componentDidMount() {
    this.handleLoad();
  }
  render() {
    return (
      <div id="home-page">
        <Helmet>
          <link rel="canonical" href="https://daralataa.com/service" />
        </Helmet>
        <section id="service">
          <div className="container">
            <div className="heading">
              <h2>WHAT WE DO</h2>
            </div>
            <br />
            <div className="row layout">
              <div className="col-md-4 col-sm-6 col-xs-12 service-c">
                <Link to="/service/building-facility-management-in-uae">
                  <div className="service-widget">
                    <div
                      className="service-image"
                      style={{ backgroundImage: `url(${service3})` }}
                    />
                    <div className="service-details">
                      <div>
                        <h3>Building Facility Management</h3>
                        <p style={{ WebkitBoxOrient: "vertical" }}>
                          Dar Al Ataa has dedicated teams that provides various
                          Building Management Services in consistent with the
                          highest standards.
                        </p>
                        <a className="btn btn-sm more-button">
                          Read More
                          <span>
                            <i className="icon-right-open" />
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 service-c">
                <Link to="/service/electrical-services-in-dubai-uae">
                  <div className="service-widget">
                    <div
                      className="service-image"
                      style={{ backgroundImage: `url(${electrical})` }}
                    />
                    <div className="service-details">
                      <div>
                        <h3>Electrical Services</h3>
                        <p style={{ WebkitBoxOrient: "vertical" }}>
                          With our regional knowledge and sector expertise Dar
                          Al Ataa Clients a fully tailored MEP solution that can
                          take an initial concept all the way through to
                          handover.
                        </p>
                        <a className="btn btn-sm more-button">
                          Read More
                          <span>
                            <i className="icon-right-open" />
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 service-c">
                <Link to="/service/best-plumbing-service-in-dubai-uae">
                  <div className="service-widget">
                    <div
                      className="service-image"
                      style={{ backgroundImage: `url(${pumbling})` }}
                    />
                    <div className="service-details">
                      <div>
                        <h3>Plumbing Services</h3>
                        <p style={{ WebkitBoxOrient: "vertical" }}>
                          With our extensive experience and industry expertise, Dar Al Ataa provides
                          clients with a comprehensive plumbing solution, guiding projects from initial 
                          design through to final handover.
                        </p>
                        <a className="btn btn-sm more-button">
                          Read More
                          <span>
                            <i className="icon-right-open" />
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 service-c">
                <Link to="/service/hvac-system-in-dubai-uae">
                  <div className="service-widget">
                    <div
                      className="service-image"
                      style={{ backgroundImage: `url(${hvac})` }}
                    />
                    <div className="service-details">
                      <div>
                        <h3> HVAC Work</h3>
                        <p style={{ WebkitBoxOrient: "vertical" }}>
                          With our extensive experience and industry expertise, Dar Al Ataa provides
                          clients with a comprehensive plumbing solution, guiding projects from initial 
                          design through to final handover.
                        </p>
                        <a className="btn btn-sm more-button">
                          Read More
                          <span>
                            <i className="icon-right-open" />
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 service-c">
                <Link to="/service/ict-service-company-in-dubai">
                  <div className="service-widget">
                    <div
                      className="service-image"
                      style={{ backgroundImage: `url(${ict})` }}
                    />
                    <div className="service-details">
                      <div>
                        <h3>ICT Systems</h3>
                        <p style={{ WebkitBoxOrient: "vertical" }}>
                        We offer comprehensive civil construction work solutions that are designed to meet the ever growing needs of Dubai’s infrastructure landscape.
                        </p>
                        <a className="btn btn-sm more-button">
                          Read More
                          <span>
                            <i className="icon-right-open" />
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 service-c">
                <Link to="/service/annual-maintenance-contracts-services-in-UAE">
                  <div className="service-widget">
                    <div
                      className="service-image"
                      style={{ backgroundImage: `url(${amc})` }}
                    />
                    <div className="service-details">
                      <div>
                        <h3>Annual Maintenance Contracts (AMC)</h3>
                        <p style={{ WebkitBoxOrient: "vertical" }}>
                        When it comes to maintaining the smooth and efficient operation of your property, regular maintenance is key. 
                        </p>
                        <a className="btn btn-sm more-button">
                          Read More
                          <span>
                            <i className="icon-right-open" />
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 service-c">
                <Link to="/service/interior-fit-out-company-in-dubai-uae">
                  <div className="service-widget">
                    <div
                      className="service-image"
                      style={{ backgroundImage: `url(${service1})` }}
                    />
                    <div className="service-details">
                      <div>
                        <h3>Interior Fit out Works</h3>
                        <p style={{ WebkitBoxOrient: "vertical" }}>
                          We offer a complete turnkey interior fit out solution
                          and in order to bring the design and layouts into
                          reality, we have a skilled team of workers comprising
                          of civil and MEP technicians.
                        </p>
                        <a className="btn btn-sm more-button">
                          Read More
                          <span>
                            <i className="icon-right-open" />
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 service-c">
                <Link to="/service/top-civil-construction-company-in-dubai-uae">
                  <div className="service-widget">
                    <div
                      className="service-image"
                      style={{ backgroundImage: `url(${service2})` }}
                    />
                    <div className="service-details">
                      <div>
                        <h3>Civil Works</h3>
                        <p style={{ WebkitBoxOrient: "vertical" }}>
                          Our skilled technicians & labor force, along with our
                          specialised subcontractors can perform their duties to
                          the best satisfaction of our clients.
                        </p>
                        <a className="btn btn-sm more-button">
                          Read More
                          <span>
                            <i className="icon-right-open" />
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              
              
              
              <div className="col-md-4 col-sm-6 col-xs-12 service-c">
                <Link to="/service/building-materials-trading-service-company-in-dubai">
                  <div className="service-widget">
                    <div
                      className="service-image"
                      style={{ backgroundImage: `url(${material})` }}
                    />
                    <div className="service-details">
                      <div>
                        <h3>Material Trading</h3>
                        <p style={{ WebkitBoxOrient: "vertical" }}>
                          We specializes in trading business of building
                          materials that establishes a strong network with
                          vendors and manufacturers.
                        </p>
                        <a className="btn btn-sm more-button">
                          Read More
                          <span>
                            <i className="icon-right-open" />
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default ServicePage;
