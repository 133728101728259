import React, { Component } from "react";
import "../../assets/scss/ContactPage.scss";
import $ from "jquery";
import { Helmet } from "react-helmet";

class ContactPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    $("#submit").click(function () {
      $.post("mail.php", $("#gform").serialize(), function (response) {
        $("#status").html(response);
        // $('#success').hide('500	');
        $("#submit").each(function () {
          this.reset();
        });
      });
      return false;
    });
  }

  render() {
    return (
      <div id="contact-page">
        <Helmet>
        <link rel="canonical" href="https://daralataa.com/contact" />
        </Helmet>
        <section className="contact-header">
          <div className="container">
            <div className="heading">
              <h2>CONTACT</h2>
            </div>
          </div>
        </section>
        <section className="contact">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <div className="heading">
                  <h3>GET A QUOTE</h3>
                </div>
                <div className="contact-form">
                  <form id={"gform"} method={"post"}>
                    <div className="row">
                      <div className="col-md-4">
                        <div>
                          <span>
                            <input
                              type="text"
                              name="name"
                              size="40"
                              placeholder="Your Name"
                            />
                          </span>
                        </div>
                        <div>
                          <span>
                            <input
                              type="email"
                              name="email"
                              size="40"
                              placeholder="Your Email"
                            />
                          </span>
                        </div>
                        <div>
                          <span>
                            <input
                              type="text"
                              name="mobile"
                              size="40"
                              placeholder="Your Number"
                            />
                          </span>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div>
                          <span>
                            <textarea
                              cols={40}
                              rows={10}
                              name={"message"}
                              placeholder="Your Message"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12 col-sm-6">
                        <button
                          id={"submit"}
                          type={"submit"}
                          className="home-btn btn-danger"
                        >
                          SUBMIT
                        </button>
                      </div>
                      <div className="col-xs-12 col-sm-6">
                        <div id="status" />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-md-4">
                <div className="heading">
                  <h3>CONTACT US</h3>
                </div>
                <div className="contact-address">
                  <ul>
                    <li>
                      <dl>
                        <dd style={{ display: "inline" }}>
                        Dar Al Ataa Technical Services LLC, Warehouse No - E35, Near Jotun Paint, Opposite Mepprox Building Materials, Al Quoz Industrial Area-2, Dubai, UAE.
                        </dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>Phone</dt>
                        <dd style={{ display: "inline" }}>
                          <a href="tel:+971545643903">+971 (0)4564 3903</a>
                        </dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>Mobile</dt>
                        <dd style={{ display: "inline" }}>
                          <a href="tel:+971549912280">+971 (0)54 991 2280</a>
                        </dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>Email</dt>
                        <dd style={{ display: "inline" }}>
                          <a href="mailto:info@daralataa.com">
                            info@daralaaa.com
                          </a>
                        </dd>
                      </dl>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="map-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3607.558063659146!2d55.35830321493548!3d25.28544788385521!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5cdc7bbd963f%3A0x337370d178203b48!2sDar+Al+Ataa+Technical+Services+LLC!5e0!3m2!1sen!2sin!4v1539156490781"
            width="100%"
            height="100%"
            frameBorder="0"
            allowFullScreen
          />
        </section>
      </div>
    );
  }
}

export default ContactPage;
