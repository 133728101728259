import React, { Component } from "react";
import { Link } from "react-router-dom";
import blogHeadImg from "../../assets/images/blog-head.jpg";
import "../../assets/scss/page-header.scss";

class Page_header extends Component {
  render() {
    const HeaderTitle = this.props.headertitle;
    const Subheader = this.props.subheader ? this.props.subheader : HeaderTitle;
    const CustomClass = this.props.customclass ? this.props.customclass : "";
    const Img = this.props.Img ? this.props.Img : blogHeadImg;

    return (
      // <div
      //   className={
      //     "ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image " +
      //     CustomClass
      //   }
      //   style={{ backgroundImage: `url(${Img})` }}
      // >
      <div className="parallax  ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__breadcrumb-inner">
                <h1 className="page-title"></h1>
                <div className="ltn__breadcrumb-list"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Page_header;
