import React, { createContext, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import $ from "jquery";

const ScrollContext = createContext();

export const useScrollContext = () => useContext(ScrollContext);

export const ScrollProvider = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    const sectionId = location.state?.sectionId;
    if (sectionId) {
      $("html, body").animate(
        {
          scrollTop: $(`#${sectionId}`).offset().top - 60,
        },
        600
      );
    }
  }, [location]);

  return <ScrollContext.Provider value={{}}>{children}</ScrollContext.Provider>;
};
