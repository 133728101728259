import React, { Component } from "react";
import "../assets/scss/Footer.scss";
import logo from "../assets/images/logo.svg";
import { Link } from "react-router-dom";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
      {/* Google Tag Manager (noscript)  */}
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MV286BJ4"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
{/* <!-- End Google Tag Manager (noscript) --> */}
      <div id="footer">
        <section className="main-footer">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-6 col-xs-12">
                <h4>CONTACTS</h4>
                <ul>
                  <li>
                    <dl>
                      <dd style={{ display: "inline" }}>
                      Dar Al Ataa Technical Services LLC, Warehouse No - E35, Near Jotun Paint, Opposite Mepprox Building Materials,<br /> Al Quoz Industrial Area-2, Dubai, UAE
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>Phone</dt>
                      <dd style={{ display: "inline" }}>
                        <a href="tel:+971545643903">+971 (0)4564 3903</a>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>Mobile</dt>
                      <dd style={{ display: "inline" }}>
                        <a href="tel:+971549912280">+971 (0)54 991 2280</a>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>Email</dt>
                      <dd style={{ display: "inline" }}>
                        <a href="mailto:info@daralataa.com">
                          info@daralataa.com
                        </a>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
              <div className="col-md-4 hidden-sm hidden-xs">
                {/*  <h4>NAVIGATION</h4>*/}
                {/*  <ul>*/}
                {/*    <li><a href='#'>About Us</a></li>*/}
                {/*    <li><a href='#'>Our Services</a></li>*/}
                {/*    <li><Link to="/contact">Contact</Link></li>*/}
                {/*  </ul>*/}
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <br className={"hidden-md hidden-sm hidden-lg"} />
                <a href="#" className="brand">
                  <img src={logo} alt="Dar Al Ataa" height="52" />
                </a>
                <p className="brand-content">
                  Dar Al Ataa is one of the leading and most favoured companies
                  to provide Facility & Property Management Services in
                  consistent with the highest standards of excellence and
                  creativity.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="sub-footer">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 footer-bottom">
                <p>
                  © Copyright {new Date().getFullYear()} - DATS LLC -{" "}
                  <a href="https://skewbird" target={"_blank"}>
                    Developed by SKEWBIRD
                  </a>
                </p>
                <div className='col-sm-6 text-right'>
                <a href='https://www.facebook.com/daralataallc/'>
                  <i className='icon-facebook' />
                </a>
                <a href='https://www.instagram.com/daralataallc/?igsh=MWJnYjh1YW12cnpzMw%3D%3D'>
                  <i className='icon-instagram' />
                </a>
                <a href='https://www.linkedin.com/company/daralataa/'>
                  <i className='icon-linkedin' />
                </a>
                </div>
              </div>
              
            </div>
          </div>
        </section>
      </div>
       </>
    );
  }
}

export default Footer;
