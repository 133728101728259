import React, { useEffect } from "react";
import "../assets/scss/TopNavbar.scss";
import { Navbar } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import $ from "jquery";

const TopNavbar = () => {
  const history = useHistory();

  useEffect(() => {

// Inject Google Tag Manager script
    const gtmScript = document.createElement("script");
    gtmScript.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-MV286BJ4');
    `;
    document.head.appendChild(gtmScript);

    $("nav").on("click", ".in a", function () {
      $(".navbar-toggle").click(); //bootstrap 3.x by Richard
    });
    $(window).scroll(function () {
      if ($(document).scrollTop() > 39) {
        $(".navbar").addClass("onScroll");
        $(".top-bar").fadeOut(300);
      } else {
        $(".navbar").removeClass("onScroll");
        $(".top-bar").fadeIn(300);
      }
    });
  }, []);

  const scrollToSection = (id) => {
    history.push("/", { sectionId: id });
  };

  return (
    <div id="top-nav">
      <div
        className="top-bar animated"
        data-animation="fadeInDown"
        data-animation-delay="200"
      >
        <div className="container">
          <div className="social">
            <a
              href="https://www.facebook.com/daralataallc/"
              className={"top-widget"}
              target="_blank"
              title="facebook"
            >
              <i className="icon-facebook" />
            </a>
            <a
              href="https://www.instagram.com/daralataallc/?igsh=MWJnYjh1YW12cnpzMw%3D%3D"
              className={"top-widget"}
              target="_blank"
              title="instagram"
            >
              <i className="icon-instagram" />
            </a>
            <a
              href="https://www.linkedin.com/company/daralataa/"
              className={"top-widget"}
              target="_blank"
              title="instagram"
            >
              <i className="icon-linkedin" />
            </a>
          </div>
          <div className="contact hidden-md hidden-sm hidden-lg">
            <div className="top-widget">
              <a href="mailto:info@daralataa.com">
                <i className="icon-mail" />
              </a>
            </div>
            <div className="top-widget">
              <a href="tel:+971559345963">
                <i className="icon icon-phone" />
              </a>
            </div>
          </div>
          <div className="contact hidden-xs">
            <div className="top-widget">
              Email Us{" "}
              <a href="mailto:info@daralataa.com">info@daralataa.com</a>
            </div>
            <div className="top-widget">
              Contact <a href="tel:+971545643903">+971 (0)4564 3903</a>
            </div>
          </div>
        </div>
      </div>
      <Navbar
        fixedTop
        className="animated"
        data-animation="fadeInDown"
        data-animation-delay="400"
      >
        <Navbar.Header>
          <Navbar.Brand>
            <Link to="/">
              <img src={logo} alt="" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <ul className="nav navbar-nav navbar-right">
            <li role="presentation" className="">
              <a onClick={() => scrollToSection("home")}>Home</a>
            </li>
            <li role="presentation" className="">
              <Link to="/about">About</Link>
            </li>
            {/* <li role='presentation' className=''><a onClick={() => scrollToSection('service')}>Services</a></li> */}
            <li role="presentation" className="">
              <Link to="/service">Services</Link>
            </li>
            <li role="presentation" className="">
              <Link to="/contact">Contact</Link>
            </li>
            <li role="presentation" className="">
              <Link to="/blogs">Blogs</Link>
            </li>
          </ul>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default TopNavbar;