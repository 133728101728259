import { Component } from "react";
import { withRouter } from "react-router-dom";
import $ from "jquery";

class OnRouteChange extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      // window.scrollTo(0, 0)
      $(".animated").appear(function () {
        var elem = $(this);
        var animation = elem.data("animation");
        if (!elem.hasClass("visible")) {
          var animationDelay = elem.data("animation-delay");
          if (animationDelay) {
            setTimeout(function () {
              elem.addClass(animation + " visible");
            }, animationDelay);
          } else {
            elem.addClass(animation + " visible");
          }
        }
      });
    }
  }
  render() {
    return this.props.children;
  }
}

export default withRouter(OnRouteChange);
